var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"page-wrap"},[_c('div',{staticClass:"page-header"},[_c('MainHeader')],1),_c('div',{staticClass:"page-main"},[_c('div',{staticClass:"page-right"},[_vm._m(0),_c('div',{staticClass:"top-search"},[_c('el-input',{staticClass:"search",attrs:{"placeholder":"请输入关键字搜索"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.goSearch.apply(null, arguments)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('el-select',{staticClass:"select",attrs:{"value-key":"table_name","placeholder":"请选择知识库"},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}},[_c('el-option',{attrs:{"label":"全部","value":""}}),_vm._l((_vm.kuList),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.table_name,"value":item.table_name}})})],2),_c('button',{staticClass:"btn",on:{"click":_vm.goSearch}},[_vm._v("搜索")])],1),_c('div',{staticClass:"page-right-main"},[_c('div',{staticClass:"mainLeft"},[_c('div',{staticClass:"leftTitle"},[_vm._v("知识导航")]),_c('el-tree',{attrs:{"lazy":"","props":_vm.props,"load":_vm.loadNode,"node-key":"uid","highlight-current":""},on:{"node-click":_vm.handleNodeClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var node = ref.node;
var data = ref.data;
return _c('span',{staticClass:"custom-tree-node"},[_c('span',{staticClass:"oneline",style:({
                    display: 'inline-block',
                    'max-width': '160px',
                    // 'max-width': '600px',
                    'font-weight':
                      data['类型'] === '语义类型' ? 'bold' : 'normal',
                  }),attrs:{"title":node.label}},[_vm._v(_vm._s(node.label))])])}}])})],1),_c('div',{staticClass:"mainCenter",attrs:{"id":"scrollBox"}},[(_vm.clickType == '实体' && _vm.data.id)?_c('div',{staticClass:"shiti"},[_c('div',{staticClass:"top-content"},[_c('div',{staticClass:"cell"},[_c('div',{staticClass:"label"},[_vm._v("实体编号：")]),_c('div',{staticClass:"content"},[_vm._v(_vm._s(_vm.data["id"]))])]),_c('div',{staticClass:"cell"},[_c('div',{staticClass:"label"},[_vm._v("实体名称：")]),_c('div',{staticClass:"content"},[_c('div',[_vm._v(_vm._s(_vm.data["实体名称"]))])])]),_c('div',{staticClass:"cell"},[_c('div',{staticClass:"label"},[_vm._v("语义类型：")]),_c('div',{staticClass:"content"},[_c('div',[_vm._v(_vm._s(_vm.data["语义类型"]))])])]),_c('div',{staticClass:"cell"},[_c('div',{staticClass:"label"},[_vm._v("上位概念：")]),_c('div',{staticClass:"content"},[(_vm.data['上位概念'].length == 0)?_c('div',[_vm._v("/")]):_vm._e(),(_vm.data['上位概念'].length > 0)?_c('div',{staticClass:"shangwei"},_vm._l((_vm.data['上位概念']),function(item,index){return _c('div',{key:index},[(index > 0)?_c('span',[_vm._v("、")]):_vm._e(),_vm._v(_vm._s(item["name"])+" ")])}),0):_vm._e()])])]),_c('div',{staticClass:"main-content"},[_c('div',{staticClass:"tupu"},[_vm._m(1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
                      _vm.tupuData.categories && _vm.tupuData.categories.length > 0
                    ),expression:"\n                      tupuData.categories && tupuData.categories.length > 0\n                    "}],ref:"Echarts1",staticClass:"echarts-box",style:({ width: _vm.getWidth() })}),(
                      _vm.tupuData.categories && _vm.tupuData.categories.length == 0
                    )?_c('div',{staticStyle:{"padding-bottom":"40px"}},[_c('Empty')],1):_vm._e()]),_c('div',{staticClass:"tabs"},[_c('div',{class:{ active: _vm.cTab == '通用' },on:{"click":function($event){_vm.cTab = '通用'}}},[_vm._v(" 通用 ")]),_c('div',{class:{ active: _vm.cTab == '主题' },on:{"click":function($event){_vm.cTab = '主题'}}},[_vm._v(" 主题 ")])]),(_vm.cTab == '主题')?_vm._l((_vm.data.attributes),function(item,index){return _c('div',{key:index,staticClass:"second-wrap",attrs:{"id":'scrollBox' + index}},[_c('div',{staticClass:"sub-title"},[_vm._v(_vm._s(item.label)+"：")]),(item.contents.length > 0)?_c('div',{staticClass:"content"},_vm._l((item.contents),function(options,optionsIndex){return _c('div',{key:optionsIndex,staticClass:"contents-wrap"},[_c('div',{staticClass:"left-con"},[(item.kind == '文本' && _vm.ifCompose)?_c('el-input',{attrs:{"type":"textarea","autosize":{ minRows: 3 },"placeholder":"请输入"},model:{value:(options['text']),callback:function ($$v) {_vm.$set(options, 'text', $$v)},expression:"options['text']"}}):_vm._e(),(item.kind == '字符串' && _vm.ifCompose)?_c('el-input',{attrs:{"type":"text","placeholder":"请输入"},model:{value:(options['text']),callback:function ($$v) {_vm.$set(options, 'text', $$v)},expression:"options['text']"}}):_vm._e(),(!_vm.ifCompose)?_c('div',[_vm._v(_vm._s(options["text"]))]):_vm._e(),_c('div',{staticClass:"source-box"},[(
                                _vm.ifCompose || (!_vm.ifCompose && options.source)
                              )?_c('div',[_vm._v(" 来源： ")]):_vm._e(),(options.source)?_c('span',[_vm._v(_vm._s(options.source))]):_vm._e(),(_vm.ifCompose && !options.source)?_c('button',{staticClass:"add",on:{"click":function($event){return _vm.openSource(options)}}},[_vm._v(" + 添加来源 ")]):_vm._e()])],1),(_vm.ifCompose)?_c('div',{staticClass:"btn-box"},[(optionsIndex == 0)?_c('img',{attrs:{"src":"/common/add.png","alt":""},on:{"click":function($event){return _vm.addOne(item.contents)}}}):_vm._e(),(optionsIndex != 0)?_c('img',{attrs:{"src":"/common/reduce.png","alt":""},on:{"click":function($event){return _vm.deleteOne(item.contents, optionsIndex)}}}):_vm._e()]):_vm._e()])}),0):_vm._e(),(item.contents.length == 0)?_c('div',[_vm._v("/")]):_vm._e()])}):_vm._e(),(_vm.cTab == '通用')?_vm._l((_vm.data.parent_attributes),function(item,index){return _c('div',{key:index,staticClass:"second-wrap",attrs:{"id":'scrollBox' + index}},[_c('div',{staticClass:"sub-title"},[_vm._v(_vm._s(item.label)+"：")]),(item.contents.length > 0)?_c('div',{staticClass:"content"},_vm._l((item.contents),function(options,optionsIndex){return _c('div',{key:optionsIndex,staticClass:"contents-wrap"},[_c('div',{staticClass:"left-con"},[(item.kind == '文本' && _vm.ifCompose)?_c('el-input',{attrs:{"type":"textarea","autosize":{ minRows: 3 },"placeholder":"请输入"},model:{value:(options['text']),callback:function ($$v) {_vm.$set(options, 'text', $$v)},expression:"options['text']"}}):_vm._e(),(item.kind == '字符串' && _vm.ifCompose)?_c('el-input',{attrs:{"type":"text","placeholder":"请输入"},model:{value:(options['text']),callback:function ($$v) {_vm.$set(options, 'text', $$v)},expression:"options['text']"}}):_vm._e(),(!_vm.ifCompose)?_c('div',[_vm._v(_vm._s(options["text"]))]):_vm._e(),_c('div',{staticClass:"source-box"},[(
                                _vm.ifCompose || (!_vm.ifCompose && options.source)
                              )?_c('div',[_vm._v(" 来源： ")]):_vm._e(),(options.source)?_c('span',[_vm._v(_vm._s(options.source))]):_vm._e(),(_vm.ifCompose && !options.source)?_c('button',{staticClass:"add",on:{"click":function($event){return _vm.openSource(options)}}},[_vm._v(" + 添加来源 ")]):_vm._e()])],1),(_vm.ifCompose)?_c('div',{staticClass:"btn-box"},[(optionsIndex == 0)?_c('img',{attrs:{"src":"/common/add.png","alt":""},on:{"click":function($event){return _vm.addOne(item.contents)}}}):_vm._e(),(optionsIndex != 0)?_c('img',{attrs:{"src":"/common/reduce.png","alt":""},on:{"click":function($event){return _vm.deleteOne(item.contents, optionsIndex)}}}):_vm._e()]):_vm._e()])}),0):_vm._e(),(item.contents.length == 0)?_c('div',[_vm._v("/")]):_vm._e()])}):_vm._e()],2)]):_vm._e(),(_vm.clickType !== '实体')?_c('div',{staticClass:"noMsg"},[_c('div',[_vm._v("温馨提示")]),_c('p',[_vm._v("请点击左侧知识导航选择需要查看的实体!")])]):_vm._e(),(_vm.clickType == '实体' && !_vm.data.id)?_c('Empty'):_vm._e()],1),(_vm.data.attributes && _vm.data.attributes.length > 0)?_c('div',{staticClass:"mainRight"},[_c('div',[_c('div',{staticClass:"title"},[_vm._v("目录")]),(_vm.cTab == '主题')?_c('div',{staticClass:"mulu"},_vm._l((_vm.data.attributes),function(item,index){return _c('div',{key:index,on:{"click":function($event){return _vm.changePart(index)}}},[_vm._v(" "+_vm._s(item.label)+" ")])}),0):_vm._e(),(_vm.cTab == '通用')?_c('div',{staticClass:"mulu"},_vm._l((_vm.data.parent_attributes),function(item,index){return _c('div',{key:index,on:{"click":function($event){return _vm.changePart(index)}}},[_vm._v(" "+_vm._s(item.label)+" ")])}),0):_vm._e()])]):_vm._e()])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"nav-box"},[_c('div',[_vm._v("实体库")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title"},[_c('span',[_vm._v("知识图谱")])])}]

export { render, staticRenderFns }